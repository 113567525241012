import { Chip } from "@mui/material";
import { getCurrentDateTime } from "src/utils/utils";

export const getFormattedQCSentData = (data) => ({
  id: {
    display: true,
    label: "id",
    data: data?.qc_management_id || "-",
  },
  project_name: {
    display: true,
    label: "project_name",
    data: data?.project_name || "-",
  },
  user_name: {
    display: true,
    label: "user_name",
    data: data?.user?.user_name || "-",
    user_id: data?.user?.user_id,
  },
  slot_id: {
    display: true,
    label: "slot_id",
    data: data?.user_slot_id || "-",
  },
  type: {
    display: true,
    label: "type",
    data: data?.qc_management_type || "-",
  },
  total_data: {
    display: true,
    label: "total_data",
    data: data?.total_data || "-",
  },
  inaccurate_data: {
    display: true,
    label: "inaccurate_data",
    data: data?.inaccurate_data?.toString() || "-",
  },
  accuracy: {
    display: true,
    label: "accuracy",
    data: data?.accurate_data?.toString() || "-",
  },
  approved_date: {
    display: true,
    label: "approved_date",
    data: data?.user_slot?.approved_date
      ? getCurrentDateTime(new Date(data?.user_slot?.approved_date))
      : "-",
    date: data?.user_slot?.approved_date,
  },
  slot_submissions: {
    display: true,
    label: "slot_submissions",
    data: data?.user_slot?.user_slot_extension_date
      ? getCurrentDateTime(new Date(data?.user_slot?.user_slot_extension_date))
      : "-",
    date: data?.user_slot?.user_slot_extension_date,
  },
  status: {
    display: true,
    label: "accuracy",
    data: data?.status ? (
      <Chip
        label={data?.status ? "In Active" : "Active"}
        sx={{
          // color: "#fff",
          fontWeight: "600",
          backgroundColor: !data?.status ? "#E0F2F1" : "#FFEBEE",
          color: !data?.status ? "#009688" : "#F44336",
          border: "1px solid",
          borderColor: !data?.status ? "#009688" : "#F44336",
        }}
      />
    ) : (
      "-"
    ),
    dataBool: data?.status,
  },
  created_date: {
    display: true,
    label: "created_date",
    data: data?.created_datetime
      ? getCurrentDateTime(new Date(data?.created_datetime))
      : "-",
    date: data?.created_datetime,
  },
  all_data: {
    display: false,
    label: "all_data",
    data: data,
  },
});

export const qcSentColumns = [
  {
    id: "id",
    label: "QC ID",
    styles: { maxWidth: "100px", width: "50px" },
    align: "center",
  },
  {
    id: "project_name",
    label: "Project Name",
    align: "left",
  },

  {
    id: "user_name",
    label: "Username",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: false,
  },
  {
    id: "slot_id",
    label: "Slot ID",
    // styles: { maxWidth: "120px", width: "120px" },
    align: "left",
    sort: false,
  },
  {
    id: "type",
    label: "QC Type",
    align: "left",
    sort: false,
  },
  {
    id: "total_data",
    label: "Total Data",
    align: "left",
    sort: false,
  },
  {
    id: "inaccurate_data",
    label: "Inaccurate Data",
    align: "left",
    sort: false,
  },
  {
    id: "accuracy",
    label: "Accuracy",
    align: "left",
    sort: false,
  },
  {
    id: "approved_date",
    label: "Approved Date",
    align: "left",
    sort: false,
  },
  {
    id: "slot_submissions",
    label: "Slot Submissions",
    align: "left",
    sort: false,
  },
  {
    id: "starus",
    label: "Starus",
    align: "center",
    sort: false,
  },
  {
    id: "created_date",
    label: "Created Date",
    align: "left",
    sort: false,
  },
  {
    label: "Actions",
    align: "center",
    styles: { maxWidth: "160px", width: "160px" },
  },
];
