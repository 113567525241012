import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTransactions: [],
    selectedTransaction: null,
    page: 0,
    dataCount: 0,
    dataPerPage: 10,
    sort: {
        updatedAt: "desc",
    },
    filter: {},
};

export const freelanceTransactionSlice = createSlice({
    name: "freelanceTransactions",
    initialState: initialState,
    reducers: {
        setAllTransactions: (state, action) => {
            state.allTransactions = action.payload;
        },
        permissionFetched: (state, action) => {
            state.selectedTransaction = action.payload;
        },
        removeSelecteContact: (state) => {
            state.selectedTransaction = null;
        },
        setPageConfigData: (state, action) => {
            switch (action.payload.type) {
                case "SET_PAGE":
                    state.page = action.payload.data;
                    break;
                case "SET_DATA_COUNT":
                    state.dataCount = action.payload.data;
                    break;
                case "SET_IS_LOADING":
                    state.isLoading = action.payload.data;
                    break;
                case "SET_DATA_PER_PAGE":
                    state.dataPerPage = action.payload.data;
                    break;
                default:
                    break;
            }
        },
        setFilter: (state, action) => {
            state.filter = {
                ...state.filter,
                ...action.payload,
            };
        },

        setSortConfig: (state, action) => {
            state.sort = {
                [action.payload.key]: action.payload.order,
            };
        },
    },
});
