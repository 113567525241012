import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allFreelancers: [],
  freelancersProfile: {},
  selectedFreelancers: null,
  filter: {},
  isLoading: false,
  page: 0,
  dataCount: 0,
  dataPerPage: 10,
  sort: {
    updatedAt: "desc",
  },
};

export const freelancersSlice = createSlice({
  name: "freelancers",
  initialState: initialState,
  reducers: {
    setAllFreelancers: (state, action) => {
      state.allFreelancers = action.payload;
    },
    freelancersFetched: (state, action) => {
      state.selectedFreelancers = action.payload;
    },
    removeSelectedPermission: (state) => {
      state.selectedFreelancers = null;
    },
    setFilter: (state, action) => {
      state.filter = action.payload.data;
    },
    setFreelancersProfile: (state, action) => {
      // console.log("action", action);
      state.freelancersProfile = action?.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
  },
});
