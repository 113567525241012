import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, MenuItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableCustomServer from "src/helper/Table/TableCustomServer";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "../../QCManagementRoute";
import { getFormattedQCData, qcColumns } from "../../QCManagementTableConfig";

const IconButtonStyle = styled(IconButton)(({ theme }) => {
  return {
    padding: "5px",
    borderRadius: "5px",
  };
});

const QCManagementTable = ({
  allQcRecord = [],
  count = 0,
  page,
  dataCount,
  dataPerPage,
  setValue,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { actions } = qcManagementSlice;

  const qcContext = useContext(QCManagementContext);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const data = allQcRecord?.map((userData, i) =>
      getFormattedQCData(userData)
    );

    setRowData(data);
  }, [allQcRecord]);

  return (
    <TableCustomServer
      rowData={rowData}
      editAction={(row) => {
        qcContext.openViewQCDialog(row.id.user_id);
        dispatch(actions.userFetched(row));
      }}
      deleteAction={(row) => {
        qcContext.deleteQC(row.id.user_id);
        dispatch(actions.userFetched(row));
      }}
      show={true}
      showViewButton={false}
      showEditButton={false}
      showDeleteButton={false}
      showMoreButton={true}
      moreIcons={(row) => [
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            qcContext.openViewQCDataDialog(row.id.data);
            dispatch(actions.qcFetched(row?.all_data));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>View QC Data</span>
        </MenuItem>,
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            qcContext.openViewQCDataSmartCheckDialog(row.id.data);
            dispatch(actions.qcFetched(row?.all_data));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>Smart Check QC Data</span>
        </MenuItem>,
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            qcContext.openViewQCDataMenualCheckDialog(row.id.data);
            dispatch(actions.qcFetched(row?.all_data));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>Manual Check QC Data</span>
        </MenuItem>,
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            // qcContext.openViewQCDataDialog(row.id.data);
            // dispatch(actions.qcFetched(row));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>Recreat QC Data</span>
        </MenuItem>,
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            setValue(1);
            // qcContext.openViewQCDataDialog(row.id.data);
            // dispatch(actions.qcFetched(row));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>Prepare QC Data</span>
        </MenuItem>,
        <MenuItem
          key={row.id.data + " CCLoginbtn"}
          onClick={() => {
            // qcContext.openViewQCDataDialog(row.id.data);
            // dispatch(actions.qcFetched(row));
          }}>
          <IconButtonStyle disableRipple aria-label="View QC Data">
            <VisibilityIcon
              sx={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.grey[800],
              }}
              color="action"
            />{" "}
          </IconButtonStyle>
          <span style={{ marginLeft: "10px" }}>Hold QC Data</span>
        </MenuItem>,
      ]}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
      handleRequestSort={(currentKey, order) => {
        const isAsc = order === "asc";
        dispatch(
          actions.setSortConfig({
            key: currentKey,
            order: isAsc ? "desc" : "asc",
          })
        );
      }}
      handleSetPage={(newPage) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_PAGE",
            data: newPage,
          })
        );
      }}
      handleNoOfRowsPerPage={(value) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_PER_PAGE",
            data: parseInt(value, 10),
          })
        );
        dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
      }}
      columnsConfig={qcColumns}
      numCols={qcColumns.length}
      deleteTooltip="Delete User"
      editTooltip="Edit User"
    />
  );
};

export default QCManagementTable;
