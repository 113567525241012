import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allQC: [],
  selectedQC: null,
  filter: {},
  selectedQCListData: null,
  selectedQCListField: null,
  page: 0,
  dataCount: 0,
  qcDataView: {
    page: 0,
    dataCount: 0,
    isLoading: false,
    dataPerPage: 25,
    data: [],
    filter: {},
  },
  dataPerPage: 25,
  sort: {
    updatedAt: "desc",
  },
  isLoading: false,
};

export const qcManagementSlice = createSlice({
  name: "qcManagement",
  initialState: initialState,
  reducers: {
    setAllQC: (state, action) => {
      state.allQC = action.payload;
    },
    qcFetched: (state, action) => {
      state.selectedQC = action.payload;
    },
    selectedQCListDataFetched: (state, action) => {
      state.selectedQCListData = action.payload;
    },
    selectedQCListFieldFetched: (state, action) => {
      state.selectedQCListField = action.payload;
    },
    removeSelectedQC: (state) => {
      state.selectedRole = null;
    },
    setQCDataView: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.qcDataView.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.qcDataView.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.qcDataView.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.qcDataView.dataPerPage = action.payload.data;
          break;
        case "SET_DATA":
          state.qcDataView.data = action.payload.data;
          break;
        case "SET_FILTER":
          state.qcDataView.filter = {
            ...state.qcDataView.filter,
            ...action.payload,
          };
          break;
        default:
          break;
      }
    },
    setPageConfigData: (state, action) => {
      switch (action.payload.type) {
        case "SET_PAGE":
          state.page = action.payload.data;
          break;
        case "SET_DATA_COUNT":
          state.dataCount = action.payload.data;
          break;
        case "SET_IS_LOADING":
          state.isLoading = action.payload.data;
          break;
        case "SET_DATA_PER_PAGE":
          state.dataPerPage = action.payload.data;
          break;
        default:
          break;
      }
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },

    setSortConfig: (state, action) => {
      state.sort = {
        [action.payload.key]: action.payload.order,
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
