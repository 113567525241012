import { Box, Divider, Paper, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getQCDataList } from "src/apis/qcManagement";
import EnhancedTableToolbar from "src/helper/EnhancedTableToolbar/EnhancedTableToolbar";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import { QCManagementContext } from "./QCManagementRoute";
import QCFilter from "./components/Filter/QCFilter";
import QCManagementApprovedTable from "./components/QCManagementTable/QCManagementApprovedTable";
import QCManagementPreparedTable from "./components/QCManagementTable/QCManagementPreparedTable";
import QCManagementSentTable from "./components/QCManagementTable/QCManagementSentTable";
import QCManagementTable from "./components/QCManagementTable/QCManagementTable";

const searchKeys = [];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QCManagement = () => {
  const { actions } = qcManagementSlice;
  const dispatch = useDispatch();
  const context = useContext(QCManagementContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { allQC, filter, isLoading } = useSelector(
    (state) => ({
      allQC: state.qcManagement.allQC || [],
      filter: state.qcManagement.filter || [],
      isLoading: state.qcManagement.isLoading,
    }),
    shallowEqual
  );

  const getAllData = () => {
    // console.log(filter?.data?.search?.keyword);
    dispatch(actions.setIsLoading(true));
    getQCDataList({
      status: 1,
      search: filter?.data?.search?.keyword || "",
      page_no: 1,
      page_record: 25,
    })
      .then((res) => {
        console.log(res?.data?.data?.qc_data);
        dispatch(actions.setAllQC(res?.data?.data?.qc_data?.rows));
      })
      .catch((data) => console.log(data.response))
      .finally(() => {
        dispatch(actions.setIsLoading(false));
      });
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <EnhancedTableToolbar
        title="QC Management"
        btnHandler={() => context.addRole()}
        refreshHandler={getAllData}
        circularLoader={
          isLoading && (
            <span style={{ marginLeft: "10px" }}>
              <Spinner animation="border" />
            </span>
          )
        }
        showAdd={false}
        tooltipTitle="Add Permission"
        btnTitle="ADD"
        shouldGetDataOnSearchInput={false}
      />
      <Divider />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
            sx={{ justifyContent: "space-evenly" }}
          >
            <Tab label="Assigned QC List" {...a11yProps(1)} />
            <Tab label="Prepared QC List" {...a11yProps(2)} />
            <Tab label="Approved QC List" {...a11yProps(3)} />
            <Tab label="Sent QC List" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <QCManagementTable
            setValue={setValue}
            allQcRecord={allQC}
            count={0}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <QCManagementPreparedTable allQcRecord={[]} count={0} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <QCManagementApprovedTable allQcRecord={[]} count={0} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <QCFilter
            searchConfig={{
              searchKeys,
              filterValue: filter?.data?.search?.keyword || "",
              setSearchConfig: (data) => {
                dispatch(actions.setFilter({ data }));
              },
            }}
          />
          <QCManagementSentTable allQcRecord={[]} count={0} />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default QCManagement;
