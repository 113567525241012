import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TableCustomServer from "src/helper/Table/TableCustomServer";
import { qcManagementSlice } from "src/store/slice/qcManagement";
import {
  getFormattedQCApprovedData,
  qcApprovedColumns,
} from "../../QCManagementApprovedTableConfig";

const QCManagementApprovedTable = ({
  allQcRecord = [],
  count = 0,
  page,
  dataCount,
  dataPerPage,
}) => {
  const dispatch = useDispatch();
  const { actions } = qcManagementSlice;

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    const data = allQcRecord?.map((userData, i) =>
      getFormattedQCApprovedData(userData)
    );

    setRowData(data);
  }, [allQcRecord]);

  return (
    <TableCustomServer
      rowData={rowData}
      editAction={(row) => {
        // qcContext.openViewQCDialog(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      deleteAction={(row) => {
        // qcContext.deleteQC(row.id.user_id);
        // dispatch(actions.userFetched(row));
      }}
      show={true}
      showViewButton={false}
      showEditButton={true}
      showDeleteButton={true}
      page={page}
      dataCount={dataCount}
      dataPerPage={dataPerPage}
      handleRequestSort={(currentKey, order) => {
        const isAsc = order === "asc";
        dispatch(
          actions.setSortConfig({
            key: currentKey,
            order: isAsc ? "desc" : "asc",
          })
        );
      }}
      handleSetPage={(newPage) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_PAGE",
            data: newPage,
          })
        );
      }}
      handleNoOfRowsPerPage={(value) => {
        dispatch(
          actions.setPageConfigData({
            type: "SET_DATA_PER_PAGE",
            data: parseInt(value, 10),
          })
        );
        dispatch(actions.setPageConfigData({ type: "SET_PAGE", data: 0 }));
      }}
      columnsConfig={qcApprovedColumns}
      numCols={qcApprovedColumns.length}
      deleteTooltip="Delete User"
      editTooltip="Edit User"
      showMoreButton={false}
    />
  );
};

export default QCManagementApprovedTable;
